@import '../../../variables.sass';
.footerContainer {
    background-color: #202020;
    min-height: 75vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $white;
    padding-top: 50px;
    overflow-y: hidden;
    &>div {
        width: 100%;
        &:first-child {
            margin-bottom: 70px;
            .findUs {
                img {
                    margin-bottom: 80px;
                    max-width: 80%; }
                p {
                    margin-bottom: 30px;
                    font-family: $Font-Bold;
                    color: $yellow;
                    font-size: 20px; }
                ul {
                    list-style: none;
                    display: flex;
                    justify-content: space-between;
                    li {
                        a {
                            color: $white;
                            transition: all 0.5s;
                            &:hover {
                                color: $yellow; }
                            svg {
                                font-size: 20px; } } } } } }
        &:last-child {
            .bottomBarContianer {
                margin-top: 40px;
                border-top: 1px solid #848384;
                padding-bottom: 40px;
                p {
                    font-family: $Font-Bold;
                    color: rgba($white, 0.4);
                    font-size: 15px; }
                ul {
                    list-style: none;
                    display: flex;
                    li {
                        margin: 0 20px;
                        &:first-child {
                            margin-left: 0 !important; }
                        &:last-child {
                            margin-right: 0 !important; }
                        a {
                            font-family: $Font-Bold;
                            color: $white;
                            text-transform: uppercase;
                            text-decoration: none;
                            font-size: 15px; } } } } } }


    h2 {
        width: 100%;
        font-family: $Font-Black;
        font-size: 90px;
        line-height: 100px;
        a {
            color: $white; } }
    .officeLocation {
        margin-bottom: 40px;
        &:last-child {
            margin-bottom: 0 !important; }
        h3 {
            color: $white;
            font-family: $Font-Bold;
            font-size: 20px;
            margin-bottom: 6px; }
        p {
            font-family: $Font-Light;
            font-size: 15px;
            line-height: 26px;
            color: rgba($white, 0.6); } } }

@media only screen and (max-width: 767px) {
    .footerContainer {
        &>div {
            &:first-child {
                margin-bottom: 50px; } }

        h2 {
            font-size: 50px;
            line-height: 60px; } } }
