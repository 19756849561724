@import '../../../variables.sass';
.smallScaleImage {
    img {
        max-width: 110px;
        object-fit: contain;
        height: 132px; } }
.generalSlider {
    .slick-prev, .slick-next {
        // width: 7px
        // height: 27px
 }        // color: $black
    [class*="slick-slide"] {
        padding-right: 40px;
        img {
            margin-bottom: 45px; }
        h2 {
            font-size: 26px;
            line-height: 1.22;
            color: $white;
            font-family: $Font-Black;
            margin-bottom: 20px;
            text-transform: uppercase; }
        p {
            font-size: 16px;
            line-height: 26px; } }
    button:not([data-role*="slick"]) {
        background-color: transparent;
        font-family: $Font-Regular;
        color: $white;
        font-size: 16px;
        padding: 20px 60px;
        border: 2px solid rgba($white, 0.3);
        border-radius: 5px;
        margin-top: 10px;
        cursor: pointer;
        transition: all 0.5s;
        outline: none;
        &:hover {
            border-color: $white; }
        a {
            color: #fff;
            text-decoration: none; } } }
.tacClients, .articles {
    color: $black !important;
    h2 {
        color: $yellow !important;
        text-shadow: 1px 1px #70707061; }
    p {
        color: $black !important;
        span {
            color: rgba($black, 0.4) !important; } } }
.tacClients {
    .slide {
        img {
            filter: grayscale(0);
            width: 70%;
            transition: all 0.5s; } } }
.articles {
    color: $black;
    [class*="slick-slide"] {
        h2 {
            color: $black !important;
            font-family: $Font-Black;
            font-size: 26px;
            line-height: 32px;
            white-space: normal; } }
    button:not([data-role*="slick"]) {
        color: $black !important;
        border-color: rgba($black, 0.3) !important;
        &:hover {
            border-color: $black !important; } } }

.sectionContainer {
    padding: 100px 0;
    p {
        &:first-of-type {
            font-family: $Font-Bold !important;
            font-size: 16px;
            color: $white;
            span {
                color: rgba($white, 0.4); } }
        &:last-of-type {
            font-family: $Font-Medium;
            font-size: 16px;
            line-height: 36px;
            color: rgba($white, 0.8); } }

    h2 {
        font-size: 60px;
        line-height: 1.2125;
        font-family: $Font-Black;
        color: $white; }
    .sliderContainer {
        margin-top: 100px;
        &>button {
            position: absolute;
            bottom: -30px;
            background-color: transparent;
            color: #fff;
            border: none;
            outline: none !important;
            font-size: 30px;
            cursor: pointer;
            &[data-role="slick-prev"] {
                left: 0; }
            &[data-role="slick-next"] {
                right: 0; } }
        .slide {
            display: flex;
            width: 100%;
            padding-left: 5px;
            outline: none; } } }

@media only screen and (max-width: 575.98px) {
 .generalSlider {
    [class*="slick-slide"] {
        padding-right: 0 !important;
        img {
            margin-bottom: 45px; }
        h2 {
            font-size: 26px !important;
            line-height: 30px !important;
            margin-bottom: 20px; }
        p {
            font-size: 16px;
            line-height: 26px; } }
    button:not([data-role*="slick"]) {
        font-size: 13px !important;
        padding: 15px 30px !important;
        margin-top: 10px; } }
 .articles {
    [class*="slick-slide"] {
        h2 {
            font-size: 26px;
            line-height: 32px;
            white-space: normal; } }
    button:not([data-role*="slick"]) {} }
 .sectionContainer {
    padding: 50px 0 !important;
    p {
        &:first-of-type {
            font-size: 16px; }
        &:last-of-type {
            font-size: 16px;
            line-height: 100px !important; } }
    h2 {
        font-size: 40px !important;
        line-height: 45px !important; }
    .sliderContainer {
        margin-top: 100px;
        &>button {
            bottom: -30px;
            font-size: 30px; }
        .slide {
            padding-left: 5px; } } } }
@media only screen and (max-width: 767px) {
    .generalSlider {
        [class*="slick-slide"] {
            padding-right: 1px; } } }

@media only screen and (max-width: 991px) {
    .generalSlider {
        [class*="slick-slide"] {
            h2 {
                font-size: 30px; } }
        button:not([data-role*="slick"]) {
            margin-top: 20px; } }
    .articles {
        [class*="slick-slide"] {
            h2 {
                line-height: 35px; } } }
    .sectionContainer {
        padding: 100px 0;
        .sliderContainer {
            margin-top: 50px; }
        h2 {
            font-size: 50px;
            line-height: 80px; } } }
