@import '../../../variables.sass';
.theteamContainer {
 padding: 100px 0px;
 h2 {
  font-size: 80px;
  text-align: left;
  text-transform: uppercase;
  font-family: $Font-Black;
  color: #333132;
  padding-bottom: 95px;
  span {
      color: $yellow;
      text-shadow: 1px 1px #70707061; } }
 .personList {
  list-style: none;
  .personOne {
   padding-left: 60px;
   margin-top: calc((100vw - 80px)/18*-1); }
  .personTwo {
   margin-top: calc((100vw - 80px)/18*-1);
   text-align: end; }
  .personThree {
   left: 0;
   margin-top: calc((100vw - 80px)/18*-1); }
  .personFour {
   margin-top: calc((100vw - 80px)/18*-1);
   text-align: end;
   padding-right: 60px; } } }

@media only screen and (max-width: 767px) {
 .theteamContainer {
  h2 {
   font-size: 40px !important; }
  .personList {
   .personOne {
    padding-left: 0px !important;
    padding-top: 30px !important; }
   .personTwo {
    text-align: start !important;
    padding-top: 30px !important;
    padding-left: 40px !important; }
   .personThree {
    padding-top: 30px !important; }
   .personFour {
    text-align: start;
    padding-right: 0px !important;
    padding-left: 40px !important;
    padding-top: 30px !important; } } } }
@media only screen and (max-width: 990px) {
 .theteamContainer {
  h2 {
   font-size: 40px !important; }
  .personList {
   .personOne {
    padding-left: 0px !important;
    padding-top: 90px; }
   .personTwo {
    text-align: start !important;
    padding-top: 90px;
    padding-left: 200px; }
   .personThree {
    padding-top: 90px; }
   .personFour {
    text-align: start;
    padding-right: 0px !important;
    padding-left: 200px;
    padding-top: 90px; } } } }





