@import "../../../variables.sass";
.firstsectionContainer {
  font-size: 24px;
  color: rgba($yellow, 0.6);
  padding: 100px 0;
  background-image: url('./img/about-us.jpg');
  background-size: cover;
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat; }
