@import '../../../variables.sass';
.brandingAndDesignContainer {
 padding: 100px 0px;
 h3 {
  color: $dark-grey;
  font-size: 56px;
  font-family: $Font-Black;
  padding-bottom: 10px; }
 p {
  font-family: $Font-Medium;
  font-size: 18px;
  line-height: 26px; }
 .BrandingAndDesignSlidertwo {
  padding: 100px 0;
  i {
   font-size: 18px;
   font-family: $Font-Regular;
   color: $dark-grey;
   display: inline-block;
   padding-bottom: 10px; }
  .sliderForClickedSection {
   [class*="slick-slide"] {
    padding-right: 25px; }
   h3 {
    font-size: 36px; } }
  button {
   position: absolute;
   bottom: -45px;
   background-color: transparent;
   color: #000;
   border: none;
   outline: none !important;
   font-size: 30px;
   cursor: pointer;
   &[data-role="slick-next"] {
    right: 25px; } } } }
@media only screen and (max-width: 575.98px) {
 .brandingAndDesignContainer {
  padding: 50px 0px !important;
  h3 {
   font-size: 40px !important; }
  p {
   font-size: 16px !important; }
  .BrandingAndDesignSlidertwo {
   padding: 50px 0 !important;
   i {
    font-size: 16px !important; }
   .sliderForClickedSection {
    [class*="slick-slide"] {
     padding-right: 25px; }
    h3 {
     font-size: 28px !important; } } } } }






