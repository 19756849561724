@import '../../../variables.sass';
.firstSectionContainer {
 padding: 100px 0 85px;
 h2 {
  font-size: 80px;
  font-family: $Font-Black;
  color: $dark-grey;
  padding-bottom: 40px;
  span {
   color: $yellow;
   text-shadow: 1px 1px #70707061; } }
 p {
  color: $dark-grey;
  font-size: 16px;
  font-family: $Font-Medium;
  line-height: 26px; } }
@media only screen and (max-width: 767px) {
 .firstSectionContainer {
  h2 {
   font-size: 40px !important; } } }





