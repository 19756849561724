@import '../../../variables.sass';
.dominosFourthSectionContainer {
  padding: 0  0 100px;
  h2 {
    font-size: 40px;
    font-family: $Font-Bold;
    color: $dark-grey; }
  p {
    font-size: 30px;
    font-family: $Font-Bold;
    color: $dark-grey;
    white-space: break-spaces; }
  div {
    img {
     padding-top: 100px; } } }
