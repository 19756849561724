@import '../../../variables.sass';
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
.creativeDevelopmentContainer {
 .title {
  font-size: 36px;
  font-family: $Font-Black;
  text-transform: uppercase;
  text-align: center;
  position: relative;
  display: inline-block;
  margin: 100px 0;
  &:before {
    display: block;
    content: '';
    width: 166px;
    border-bottom: 2px solid #00b0b3;
    position: absolute;
    left: 50%;
    bottom: -45px;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%); }
  &:after {
    content: '01';
    font-family: $Font-Light;
    font-size: 160px;
    color: rgba(0,0,0,.1);
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
    -moz-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    -o-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%); } }
 .bg {
  min-height: 1080px;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 145px 80px;
  background-position: center;
  padding-top: 400px;
  .challenge {
   font-size: 45px;
   font-family: 'Bebas Neue', cursive;
   line-height: 45px;
   color: #000d50;
   padding: 0;
   margin: 0;
   margin-bottom: 20px; }
  .challengeContent {
   font-size: 20px;
   font-family: $Font-Regular;
   line-height: 30px;
   color: #4e4e4e;
   font-weight: 700;
   margin-bottom: 40px; }
  .center {
   text-align: center; } }
 .first {
  background-image: url("../../../imgs/dove-challange.jpeg"); }
 .second {
  background-image: url("../../../imgs/dove-you-are-stronger-than-you-think.jpeg"); }
 .bgMobile {
  display: none; } }
@media only screen and (max-width: 959px) {
 .creativeDevelopmentContainer {}
 .title {
  font-size: 30px;
  margin: 100px 0; }
 .bg {
  min-height: auto !important;
  background-image: none !important;
  padding: 20px 40px !important;
  background-position: center;
  padding-top: 400px;
  .challenge {
   font-size: 45px;
   font-family: 'Bebas Neue', cursive;
   line-height: 45px;
   color: #000d50;
   padding: 0;
   margin: 0;
   margin-bottom: 20px; }
  .challengeContent {
   font-size: 20px;
   font-family: $Font-Regular;
   line-height: 30px;
   color: #4e4e4e;
   font-weight: 700;
   margin-bottom: 40px; }
  .center {
   text-align: center; } }
 .bgMobile {
  display: block !important; } }



