@import '../../../variables.sass';
.caseStudyContainer {
 background-size: cover;
 background-position: center;
 padding: 100px 0;
 img {
  width: auto;
  padding-bottom: 35px; }
 h2 {
  font-size: 32px;
  font-family: $Font-Black;
  padding-bottom: 26px; }
 p {
  font-size: 16px;
  font-family: $Font-Medium;
  padding-bottom: 26px; }
 button {
  padding: 15px 20px;
  font-size: 16px;
  font-family: $Font-Regular;
  background-color: transparent;
  outline: none;
  border: 2px solid;
  border-radius: 3px;
  cursor: pointer;
  a {} } }
