@import '../../variables.sass';
.container {
 overflow-y: hidden;
 .Ybg {
  background-repeat: no-repeat;
  background-position: 100% 101%;
  padding: 160px 0px 68px;
  color: $black;
  position: relative;
  &:after {
   content: "";
   position: absolute;
   bottom: 0;
   right: 0;
   background-size: 100%;
   background-position: bottom;
   transform: translateX(50%);
   width: 100%;
   height: 100%;
   background-repeat: no-repeat; }
  .title {
   padding-bottom: 120px;
   text-align: center;
   h2 {
    text-transform: uppercase;
    font-family: $Font-Bold;
    font-size: 35px;
    margin: 0;
    padding-bottom: 15px;
    text-align: center;
    position: relative;
    display: inline-block; }
   span {
    font-size: 20px;
    font-family: $Font-Regular;
    color: rgba(0,0,0,.5);
    margin-bottom: 106px;
    position: absolute;
    left: 0;
    text-align: left; } }
  p {
   font-family: $Font-Bold;
   font-size: 30px;
   padding-bottom: 39px; }
  .socialIcon {
   position: absolute;
   bottom: 80px;
   ul {
    list-style: none;
    display: flex;
    li {
     padding-right: 20px;
     svg {
      font-size: 28px; }
     a {
      color: $dark-grey2; } } } }
  .contactListIcon {
   width: 80%;
   padding-bottom: 70px;
   li {
    font-size: 20px;
    font-family: $Font-Medium;
    list-style: none;
    padding-bottom: 60px;
    line-height: 26px;
    &:first-child {
     svg {
      transform: rotate(90deg); } }
    svg {
     color: $dark-grey2;
     margin-right: 16px; } } } }
 .Bbg {
  background: $black;
  z-index: 0;
  padding: 160px 0px 100px;
  .gridContainer {
   padding: 0px 60px;
   position: relative; }
  .formForContactUs {
   .error {
    color: red;
    font-family: $Font-Medium;
    font-size: 12px;
    position: absolute;
    bottom: 60px;
    left: 60px; }
   label {
    text-transform: capitalize;
    color: $white;
    font-size: 23px;
    font-family: $Font-Bold;
    padding-bottom: 30px;
    display: inline-block; }
   select {
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;
    background-image: url("../../imgs/icon-arrow-down.png");
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-position-y: 25%;
    background-size: 17.46px 9.98px;
    option {
     color: black; } }
   .input {
    width: 100%;
    border: none;
    text-transform: capitalize;
    border-bottom: 1px solid #707070;
    background-color: transparent;
    font-size: 22px;
    font-family: $Font-Regular;
    color: rgba(255,255,255,1);
    margin-bottom: 80px;
    padding-bottom: 15px;
    &:focus {
     outline: none; }
    &::placeholder {
     color: rgba(255,255,255,.8); } }
   textarea {
    resize: none;
    min-height: 110px; }
   .fileUpload {
    position: relative;
    font-size: 16px;
    font-family: $Font-Regular;
    color: $white;
    span {
     position: absolute;
     bottom: -10px;
     left: 0; }
    .saveIcon {
     position: absolute;
     right: 0;
     top: 50%;
     transform: translateY(-50%);
     margin-right: 3%; }
    label {
     border: 1px solid rgba(255,255,255,.1);
     border-radius: 4px;
     padding: 0 18px;
     color: $white;
     font-size: 16px;
     font-family: $Font-Medium;
     background-color: transparent;
     width: 188px;
     height: 56px;
     display: block;
     line-height: 56px;
     text-align: center;
     &:hover {
       border: 1px solid rgba(255,255,255,1); } }
    input[type=file] {
     display: none; } }
   .btnSubmit {
    button {
     color: $white;
     border: 1px solid rgba(255,255,255,.1);
     width: 188px;
     font-size: 16px;
     font-family: $Font-Regular;
     height: 56px;
     background-color: transparent;
     border-radius: 4px;
     display: block;
     &:hover {
       border: 1px solid rgba(255,255,255,1); }
     &:focus {
      outline: none; }
     a {
      color: $white;
      text-decoration: none;
      line-height: 56px; } } } } } }


@media only screen and (max-width: 1440px) {
 .container {
  overflow-y: auto;
  .Ybg {
   padding: 140px 0px 58px;
   background-position: 125% 101%; }
  .title {
   padding-bottom: 100px !important;
   h2 {
    font-size: 25px !important; }
   span {
    font-size: 15px !important;
    margin-bottom: 90px !important; } }
  .socialIcon {
   bottom: 60px !important;
   ul {
    li {
     padding-right: 15px !important;
     svg {
      font-size: 20px !important; } } } }
  .contactListIcon {
   li {
    font-size: 18px !important;
    padding-bottom: 40px !important;
    svg {
     margin-right: 14px !important; } } }
  .Bbg {
   padding: 140px 0px 58px !important;
   .gridContainer {
    padding: 0px 30px; }
   .formForContactUs {
    .error {
     font-size: 10px !important;
     bottom: 50px !important;
     left: 30px !important; }
    label {
     font-size: 18px !important;
     padding-bottom: 25px !important; }
    .input {
     font-size: 19px !important;
     margin-bottom: 50px !important;
     padding-bottom: 10px; }
    textarea {
     min-height: 90px !important; }
    .btnSubmit {
     button {
      width: 160px !important;
      font-size: 14px !important;
      height: 45px !important;
      margin-bottom: 40px;
      a {
       line-height: 45px; } } } } } } }








