@import '../../../variables.sass';
.sectionContainer {
	padding: 100px 0;
	overflow-y: hidden;
	position: relative;
	background-size: cover;
	&:before {
		content: "";
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		background-color: rgba($black, 0.7); }
	&>div {
		height: 100%; }
	.section {
		position: relative;
		z-index: 1;
		.contentContainer {
			height: 100%;
			>div {
				color: $white;
				&:first-child {
					p {
						font-family: $Font-Bold;
						font-size: 16px;
						span {
							color: rgba($white, 0.4); } }
					h2 {
						font-family: $Font-Black;
						font-size: 80px;
						line-height: 1.125;
						white-space: break-spaces; } }
				&:last-child {
					p {
						font-size: 18px;
						line-height: 36px;
						margin-bottom: 20px;
						&.bold {
							font-family: $Font-Bold; }
						&.medium {
							font-family: $Font-Medium; } }
					&>div {
						display: flex;
						button {
							background-color: transparent;
							border: 2px solid rgba($white, 0.3);
							border-radius: 5px;
							color: $white;
							font-family: $Font-Regular;
							transition: all 0.5s;
							outline: none;
							&:hover {
								border-color: $white; }
							a {
							 color: #fff;
							 text-decoration: none; }
							&:first-child {
									margin-right: 20px;
									padding: 20px 40px;
									font-size: 16px;
									text-transform: uppercase; }
							&:last-child {
									padding: 20px;
									font-size: 16px; } } } } } } } }
@media only screen and (max-width: 991px) {
	.sectionContainer {
		padding: 100px 0;
		.section {
			.contentContainer {
				>div {
					&:first-child {
						h2 {
							font-size: 50px;
							line-height: 90px; } } } } } } }
@media only screen and (max-width: 575.98px) {
 .sectionContainer {
    padding: 50px 0; }
	.section {
		.contentContainer {
			>div {
				&:first-child {
					p {
						line-height: 100px !important; }
					h2 {
						font-size: 40px !important;
						line-height: 45px !important; } }
				&:last-child {
					p {
						margin-top: 30px; }
					&>div {
						button {
							a {}
							&:first-child {
									margin-right: 20px;
									padding: 15px 30px !important;
									font-size: 13px !important;
									text-transform: uppercase;
									margin-bottom: 20px; }
							&:last-child {
									padding: 15px !important;
									font-size: 13px !important; } } } } } } } }
