@import '../../../variables.sass';
.humblebeginningsContainer {
  padding: 100px 0;
  background-color:#202020 {}
  .headandimg {
    h2 {
     font-size: 56px;
     font-family: $Font-Black;
     color: $white;
     padding-bottom: 42px;
     span {
      color: $yellow; } }
    .image {
     position: relative;
     display: inline-block;
     overflow: hidden;
     max-width: 624px;
     height: 450px;
     padding-right: 30px;
     img {
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
      max-width: none;
      width: 115%; } } }
  .paragraph {
   padding-top: 200px;
   color: $white;
   font-family: $Font-Light;
   h3 {
    padding-bottom: 30px;
    font-size: 32px;
    font-family: $Font-Bold; }
   p {
    font-size: 16px;
    font-family: $Font-Medium;
    padding-bottom: 30px;
    line-height: 30px; }
   button {
    padding: 15px 30px;
    background-color: transparent;
    border: 2px solid rgba(255,255,255, 0.3);
    outline: none;
    border-radius: 3px;
    font-size: 16px;
    font-family: $Font-Regular;
    cursor: pointer;
    &:hover {
     border: 2px solid rgba(255,255,255, 1); }
    a {
     color: $white;
     text-decoration: none; } } } }
@media only screen and (max-width: 767px) {
 .humblebeginningsContainer {
  h2 {
   font-size: 40px !important; }
  h3 {
    font-size: 20px !important; }
  .paragraph {
   padding-top: 30px !important; } } }


