@import '../../variables.sass';
.thankYouContainer {
 padding: 200px 200px;
 background-color: $dark-grey;
 height: 100vh;
 color: $white;
 position: relative;
 .thankYouContent {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  h3 {
   font-family: $Font-Black;
   font-size: 60px; }
  span {
   font-family: $Font-Medium;
   font-size: 18px; } } }


