@import '../../../variables.sass';
.caseStudiesContainer {
 .ctnContainer {
  color: $dark-grey; }
 .ctnContainerW {
  color: $white; }
 .justify {
  justify-content: flex-end; }
 .justifyS {
  justify-content: flex-start; }
 .btnCaseStudy {
  border-color: rgba(32,32,32,.3);
  color: $dark-grey;
  &:hover {
   border-color: rgba(32,32,32,1); } }
 .btnCaseStudyW {
  border-color: rgba(255,255,255,.3);
  color: $white;
  &:hover {
   border-color: rgba(255,255,255,1); } } }
@media only screen and (max-width: 767px) {
 .caseStudiesContainer {
  .justify {
   justify-content: flex-start; } } }

