@import '../../../variables.sass';
.dominosSecondSectionContainer {
    aside {
        p {
         font-size: 16px;
         font-family: $Font-Medium;
         color: $dark-grey;
         opacity: .4;
         padding-bottom: 5px; }
        h3 {
         font-size: 20px;
         font-family: $Font-Bold;
         color: $dark-grey;
         padding-bottom: 40px; } }
    h2 {
        font-size: 40px;
        font-family: $Font-Bold;
        color: $dark-grey;
        padding-bottom: 40px; }
    p {
        font-size: 30px;
        font-family: $Font-Bold;
        color: $dark-grey;
        white-space: break-spaces; }
    div {
        img {
             padding-top: 100px; } } }


