@import '../../../variables.sass';
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
.trafficContainer {
 background-color: #49c5d1;
 padding-bottom: 60px;
 .title {
  font-size: 36px;
  font-family: $Font-Black;
  text-transform: uppercase;
  text-align: center;
  position: relative;
  display: inline-block;
  margin: 100px 0;
  color: $white;
  z-index: 1;
  &:before {
    display: block;
    content: '';
    width: 166px;
    border-bottom: 2px solid #00b0b3;
    position: absolute;
    left: 50%;
    bottom: -45px;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%); }
  &:after {
    content: '04';
    font-family: $Font-Light;
    font-size: 160px;
    color: rgba(0,0,0,.1);
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: -1;
    -webkit-transform: translate(-50%,-50%);
    -moz-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    -o-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%); } }
 .trafficContent {
  background: $white;
  color: $black;
  padding-top: 50px !important; }
 .challenge {
  font-size: 40px;
  font-family: 'Bebas Neue', cursive;
  line-height: 40px;
  color: $white;
  padding: 0;
  margin: 0;
  margin-bottom: 20px; }
 .challengeContent {
  font-size: 20px;
  font-family: $Font-Regular;
  line-height: 30px;
  color: $black;
  font-weight: 700;
  margin-bottom: 40px; } }

