@import '../../../variables.sass';
.header {
    width: 100%;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    z-index: 1000;
    background: $black;
    top: 0;
    left: 0;
    transition: all 0.5s;
    &:after {
        content: "";
        position: absolute;
        top: 0;
        height: 100%;
        background: #fff102;
        z-index: -1; }
    &.fixed {
        position: fixed;
        // background-color: #fff
        // transform: translateY(-100%)
        // transition: all 0.5s
        // &.active
 }        //     transform: translateY(0)
    &>div {
        &:first-child {
            img {
                width: 100%;
                max-width: 235.1px; } }
        &:last-child {
            display: flex;
            align-items: center;
            &>span {
                margin-right: 40px;
                font-family: $Font-Medium;
                font-size: 16px;
                a {
                    color: $white;
                    text-decoration: none; } }
            button {
                background-color: transparent;
                border: none;
                cursor: pointer;
                outline: none !important;
                position: relative;
                z-index: 2;
                span {
                    transition: all 0.5s;
                    display: block;
                    border: 1.5px solid $white;
                    border-radius: 60px;
                    background-color: $black;
                    &:first-child, &:last-child {
                        width: 31.49px;
                        transform-origin: left; }
                    &:nth-child(2) {
                        width: 25.79px;
                        margin: 9.5px 0; }
                    &:before {
                        top: -9.5px; }
                    &:after {
                        top: 9.5px; } }
                &.active {
                    span {
                        &:first-child {
                            transform: rotateZ(49deg); }
                        &:last-child {
                            transform: rotateZ(-49deg); }
                        &:nth-child(2) {
                            opacity: 0;
                            margin: 8.5px 0; } } } } } }
    .bg {
        background: beige;
        height: 100%; } }
@media only screen and (max-width: 767px) {
    .header {
        &>div {
            &:first-child {
                img {
                    max-width: 135.1px; } }
            &:last-child {
                &>span {
                    display: none; } } } } }
@media only screen and (max-width: 960px) {
    .header {
        &:after {
            display: none; } } }
