$linear-yellow: linear-gradient(180deg, rgba(255,241,0,1) 0%, rgba(255,241,10,1) 50%, rgba(255,215,0,1) 100%);
$yellow: #FCEE21;
$blue: #00D5D5;
$dark-grey: #202020;
$dark-grey2: #565656;
$black: #000;
$white: #fff;
$light-grey: #F1F2F2;
$Font-Bold: 'Montserrat-Bold';
$Font-Light: 'Montserrat-Light';
$Font-Regular: 'Montserrat-Regular';
$Font-Medium: 'Montserrat-Medium';
$Font-Black: 'Montserrat-Black';
