@import '../../../variables.sass';
.sliderItemContainer {
 background-color: rgba(113,113,113,0.2);
 color: $white;
 width: 100%;
 height: 140px;
 border-radius: 5px;
 position: relative;
 cursor: default;
 h3 {
  position: absolute;
  top: 50%;
  opacity: .2;
  transform: translateY(-50%);
  text-align: center;
  font-size: 36px;
  font-family: $Font-Black;
  text-transform: uppercase;
  text-align: left;
  padding-left: 20px; }
 &:hover {
  h3 {
   opacity: 1; } } }


@media only screen and (max-width: 1279px) {
 .sliderItemContainer {
   h3 {
    font-size: 28px; } } }
@media only screen and (max-width: 959px) {
 .sliderItemContainer {
   h3 {
    font-size: 25px; } } }

