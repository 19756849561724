@import '../../../variables.sass';
.downloadProfileContainer {
  font-family: $Font-Black;
  padding: 80px 0;
  .line {
    width: 100%;
    height: 1px;
    background-color: #858384;
    margin: 0px 0 80px; }
  h3 {
    color: #858384;
    font-size: 26px;
    font-family: $Font-Medium; }
  a {
    color: $yellow;
    font-size: 80px;
    font-family: $Font-Black;
    text-shadow: 1px 1px #70707061; } }

@media only screen and (max-width: 959px) {
  .downloadProfileContainer {
    h3 {
      font-size: 20px; }
    a {
      font-size: 40px; } } }
