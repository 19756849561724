@import '../../../variables.sass';
.subscribeFormSection {
    padding: 80px 0;
    background: $light-grey;
    .subscribeForm {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        flex-wrap: wrap;
        input {
            flex: 0 1 50%;
            color: $dark-grey;
            background-color: transparent;
            border: none;
            font-family: $Font-Medium;
            font-size: 30px;
            outline: none !important;
            max-width: 100%;
            &::placeholder {
                color: rgba($dark-grey, .6); } }
        button {
            padding: 20px 50px;
            border: 2px solid rgba($dark-grey, 0.6);
            border-radius: 5px;
            outline: none !important;
            cursor: pointer;
            background-color: transparent;
            font-family: $Font-Regular;
            font-size: 16px;
            color: rgba($dark-grey, 0.6);
            transition: all 0.5s;
            &:hover {
                border-color: rgba($dark-grey, 1);
                color: rgba($dark-grey, 1); } } } }
@media only screen and (max-width: 991px) {
    .subscribeFormSection {
        .subscribeForm {
            input {
                font-size: 20px;
                flex-grow: 1; }
            button {
                flex-grow: 1;
                margin-top: 30px; } } } }
