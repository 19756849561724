@import '../../../variables.sass';
.sectionContainer {
    padding: 100px 0;
    background-color: $black;
    .sectionName {
        font-family: $Font-Bold;
        font-size: 16px;
        color: $white; }
    p {
        font-family: $Font-Regular;
        font-size: 16px;
        line-height: 26px;
        color: rgba($white, 1);
        span {
            font-family: $Font-Bold;
            font-size: 16px;
            color: rgba($white, 0.4); } }

    .btn {
        align-self: center; }
    h2 {
        font-size: 60px;
        line-height: 1.2125;
        font-family: $Font-Black;
        color: $white;
        white-space: pre; }
    .sectionTitle2 {
        color: $yellow; }
    .sliderContainer {
        margin-top: 100px;
        h2 {
            color: $white; }
        &>button {
            position: absolute;
            bottom: -30px;
            background-color: transparent;
            color: $yellow;
            border: none;
            outline: none !important;
            font-size: 30px;
            cursor: pointer;
            &[data-role="slick-prev"] {
                left: 0; }
            &[data-role="slick-next"] {
                right: 0; } }
        .slide {
            display: flex;
            width: 100%;
            padding-left: 5px;
            outline: none; } } }
@media only screen and (max-width: 991px) {
    .sectionContainer {
        padding: 100px 0;
        .sliderContainer {
            margin-top: 50px; }
        h2 {
            font-size: 50px;
            line-height: 80px; } } }
@media only screen and (max-width: 575.98px) {
 .sectionContainer {
    padding: 50px 0;
    .sectionName {
        line-height: 100px; }
    p {
        margin-top: 30px;
        span {
            font-size: 16px; } }
    h2 {
        font-size: 40px !important;
        line-height: 45px !important; }
    .sliderContainer {
        margin-top: 50px;
        &>button {
            bottom: -30px;
            font-size: 30px; } } } }
