@import '../../../variables.sass';
.mobileAppContainer {
 .mobileApp {
  background-color: #000d50;
  padding-bottom: 50px;
  .title {
   font-size: 36px;
   color: $white;
   font-family: $Font-Black;
   text-transform: uppercase;
   text-align: center;
   position: relative;
   display: inline-block;
   margin: 100px 0;
   &:before {
    display: block;
    content: '';
    width: 166px;
    border-bottom: 2px solid #00b0b3;
    position: absolute;
    left: 50%;
    bottom: -45px;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%); }
   &:after {
    content: '02';
    font-family: $Font-Light;
    font-size: 160px;
    color: rgba(0,0,0,.1);
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
    -moz-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    -o-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%); } } }
 .paragraph {
  font-size: 22.5px;
  color: $white;
  font-family: $Font-Light;
  text-align: center; }
 .bg {
  min-height: 1080px;
  background-size: cover;
  background-repeat: no-repeat;
  padding-left: 125.5px;
  padding-right: 125.5px;
  padding-top: 400px;
  background-position: center;
  .developing {
   font-size: 45px;
   font-family: 'Bebas Neue', cursive;
   line-height: 45px;
   color: #000d50;
   padding: 0;
   margin: 0;
   margin-bottom: 20px; }
  .developingContent {
   font-size: 20px;
   font-family: $Font-Regular;
   line-height: 30px;
   color: #4e4e4e;
   font-weight: 700;
   margin-bottom: 40px; } }
 .first {
  background-image: url("../../../imgs/dove-deloping-mobile-app-img.jpeg"); }
 .second {
  background-image: url("../../../imgs/seven-day-challange-img.jpeg"); }
 .third {
  background-image: url("../../../imgs/dove-rewarding-the-ones.jpeg"); }
 .fourth {
  background-image: url("../../../imgs/dove-by-the-end-of-technotest.jpeg");
  padding-top: 800px; }
 .bgMobile {
  display: none; } }
@media only screen and (max-width: 959px) {
 .mobileAppContainer {}
 .mobileApp {
  padding-bottom: 50px;
  .title {
   font-size: 34px;
   margin: 100px 0; } }
 .paragraph {
  font-size: 20px; }
 .bg {
  min-height: auto !important;
  background: none !important;
  padding: 20px 40px !important;
  .developing {
   font-size: 40px;
   line-height: 40px;
   margin-bottom: 20px; }
  .developingContent {
   font-size: 18px;
   line-height: 30px;
   margin-bottom: 40px; } }
 .bgMobile {
  display: block !important; } }

