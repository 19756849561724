@import '../../../variables.sass';
.firstSectionContainer {
 .bgGrey {
  padding: 100px 0px;
  background-color: $dark-grey;
  h2 {
   color: $white;
   font-family: $Font-Black;
   font-size: 56px;
   padding-bottom: 25px;
   span {
    color: $yellow; } }
  p {
   font-family: $Font-Medium;
   font-size: 18px;
   color: $white;
   line-height: 1.6;
   padding-bottom: 100px; } }
 .titleContainer {
  padding-bottom: 10px;
  font-size: 32px;
  font-family: $Font-Bold;
  color: $white;
  i {
   font-size: 18px;
   color: $yellow;
   font-family: Montserrat-Regular; } }
 .sliderForServices {
  [class*="slick-slide"] {
   padding-right: 25px; }
  button {
   position: absolute;
   bottom: -45px;
   background-color: transparent;
   color: $white;
   border: none;
   outline: none !important;
   font-size: 30px;
   cursor: pointer;
   &[data-role="slick-next"] {
    right: 25px; } }
  .slick-disabled {
   display: none !important; } } }
@media only screen and (max-width: 575.98px) {
 .firstSectionContainer {}
 .bgGrey {
  h2 {
   font-size: 40px !important; }
  p {
   font-size: 16px !important;
   padding-bottom: 50px !important; } }
 .titleContainer {
  font-size: 25px !important;
  i {
   font-size: 15px !important; } } }






