@import '../../variables.sass';
.cairokeeContainer {
 .title {
  position: relative;
  padding-bottom: 35px;
  margin-bottom: 45px;
  text-align: center;
  a {
   font-size: 36px;
   line-height: 1.2;
   font-weight: 400;
   text-transform: uppercase;
   font-family: $Font-Black;
   text-decoration: none;
   transition: color .25s,border-color .25s,background-color .25s,opacity .25s ease 0s;
   color: $black;
   &:hover {
    color: #00b0b3; }
   &:after {
    display: block;
    content: '';
    width: 166px;
    border-bottom: 2px solid #00b0b3;
    position: absolute;
    left: 50%;
    bottom: 0;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%); } } }
 .portfolioMeta {
  display: inline-block;
  padding-left: 30px;
  .subTitle {
   font-family: $Font-Bold;
   font-size: 12px;
   letter-spacing: 0;
   text-transform: uppercase; }
  .link {
   font-family: $Font-Regular;
   font-size: 12px;
   letter-spacing: 0;
   text-transform: uppercase;
   color: #00b0b3;
   text-decoration: none;
   padding-left: 5px; } }
 .paragraphContainer {
  margin-top: 40px;
  margin-bottom: 60px;
  .paragraph {
   font-size: 22.5px;
   line-height: 1.4;
   margin-bottom: 20px;
   font-family: $Font-Light;
   text-align: center; } } }
