@import './variables.sass';
@font-face {
  font-family: 'Montserrat-Medium';
  src: url('./fonts/Montserrat-Medium.eot');
  src: local('Montserrat Medium'), local('Montserrat-Medium'), url('./fonts/Montserrat-Medium.eot?#iefix') format('embedded-opentype'), url('./fonts/Montserrat-Medium.woff2') format('woff2'), url('./fonts/Montserrat-Medium.woff') format('woff'), url('./fonts/Montserrat-Medium.ttf') format('truetype'), url('./fonts/Montserrat-Medium.svg#Montserrat-Medium') format('svg');
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: 'Montserrat-Light';
  src: url('./fonts/Montserrat-Light.eot');
  src: local('Montserrat Light'), local('Montserrat-Light'), url('./fonts/Montserrat-Light.eot?#iefix') format('embedded-opentype'), url('./fonts/Montserrat-Light.woff2') format('woff2'), url('./fonts/Montserrat-Light.woff') format('woff'), url('./fonts/Montserrat-Light.ttf') format('truetype'), url('./fonts/Montserrat-Light.svg#Montserrat-Light') format('svg');
  font-weight: 300;
  font-style: normal; }


@font-face {
  font-family: 'Montserrat-Regular';
  src: url('./fonts/Montserrat-Regular.eot');
  src: local('Montserrat Regular'), local('Montserrat-Regular'), url('./fonts/Montserrat-Regular.eot?#iefix') format('embedded-opentype'), url('./fonts/Montserrat-Regular.woff2') format('woff2'), url('./fonts/Montserrat-Regular.woff') format('woff'), url('./fonts/Montserrat-Regular.ttf') format('truetype'), url('./fonts/Montserrat-Regular.svg#Montserrat-Regular') format('svg');
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Montserrat-Black';
  src: url('./fonts/Montserrat-Black.eot');
  src: local('Montserrat Black'), local('Montserrat-Black'), url('./fonts/Montserrat-Black.eot?#iefix') format('embedded-opentype'), url('./fonts/Montserrat-Black.woff2') format('woff2'), url('./fonts/Montserrat-Black.woff') format('woff'), url('./fonts/Montserrat-Black.ttf') format('truetype'), url('./fonts/Montserrat-Black.svg#Montserrat-Black') format('svg');
  font-weight: 900;
  font-style: normal; }

@font-face {
  font-family: 'Montserrat-Bold';
  src: url('./fonts/Montserrat-Bold.eot');
  src: local('Montserrat Bold'), local('Montserrat-Bold'), url('./fonts/Montserrat-Bold.eot?#iefix') format('embedded-opentype'), url('./fonts/Montserrat-Bold.woff2') format('woff2'), url('./fonts/Montserrat-Bold.woff') format('woff'), url('./fonts/Montserrat-Bold.ttf') format('truetype'), url('./fonts/Montserrat-Bold.svg#Montserrat-Bold') format('svg');
  font-weight: bold;
  font-style: normal; }


html {
  &>canvas {
    display: none; } }

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box; }

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0; }

img {
  width: 100%;
  display: block; }

.sticky-copyright {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  height: 100vh;
  align-items: center;
  padding: 25px;
  position: fixed;
  pointer-events: none;
  z-index: 1001;
  p {
    writing-mode: vertical-lr;
    transform: rotateZ(180deg);
    font-family: $Font-Medium;
    color: $black;
    a {
      font-family: $Font-Bold;
      font-size: 16px;
      color: inherit;
      pointer-events: all; } } }
@media only screen and (max-width: 991px) {
  main {
    //margin-top: 100px
    .sticky-copyright {
      display: none; } } }
