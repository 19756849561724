@import '../../variables.sass';
.DominosContainer {
  .sliderCaseStudyContainer {
    background: #393939;
    padding: 100px 0;
    .sliderContainer {
        &>button {
            position: absolute;
            bottom: -30px;
            background-color: transparent;
            color: #fff;
            border: none;
            outline: none !important;
            font-size: 30px;
            cursor: pointer;
            &[data-role="slick-prev"] {
                left: 0; }
            &[data-role="slick-next"] {
                right: 0; } }
        .slide {
            display: flex;
            width: 100%;
            padding-left: 5px;
            outline: none;
            h2 {
             color: $white;
             font-family: $Font-Black;
             font-size: 56px; }
            p {
             color: $white;
             font-family: $Font-Medium;
             font-size: 26px;
             opacity: .4; } } } } }

