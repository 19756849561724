@import '../../../variables.sass';
.itemNewsContainer {
 .gridForItemNews {
  padding-bottom: 100px;
  .contentItemNewsContainer {
   padding: 0 10px;
   .titleForItemNewsContainer {
    display: flex;
    justify-content: space-between;
    color: $dark-grey;
    padding-bottom: 20px; }
   .leftTitleForItemNews {
    font-size: 16px;
    font-family: $Font-Medium; }
   .rightTitleForItemNews {
    font-size: 16px;
    font-family: $Font-Medium; }
   .imgForItemNews {
    padding-bottom: 20px;
    cursor: pointer; }
   .titleForImgForItemNews {
    color: #333132;
    font-size: 26px;
    font-family: $Font-Black;
    cursor: pointer;
    padding-bottom: 20px; }
   .paragraphForImgForItemNews {
    color: $dark-grey;
    overflow: hidden;
    opacity: .6;
    font-size: 18px;
    font-family: $Font-Medium;
    cursor: pointer; } } } }




