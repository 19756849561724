@import '../../../variables.sass';
.menu {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	opacity: 0;
	transition: all 0.5s;
	display: grid;
	background-color: $dark-grey;
	grid-template-columns: 1fr 1fr;
	grid-template-areas: "get-quote menu";
	grid-gap: 9%;
	align-items: center;
	&>div {
		grid-area: get-quote;
		align-self: end;
		padding-bottom: 70px;
		padding-left: 70px;
		background-color: $white;
		height: 100%;
		position: relative;
		border-right: 1px solid rgba($black, 0.2);
		transition: all 0.5s;
		&:hover {
			background-color: $yellow;
			border-color: $yellow;
			&>div {
				transform: translateY(-45vh); }
			img {
				height: auto;
				transform: scale(1); } }
		&>div {
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			height: 100%;
			transition: all 0.5s;
			h1 {
				font-size: 60px;
				line-height: 1;
				font-family: $Font-Black; }
			p {
				font-size: 20px;
				font-family: $Font-Medium; }
			a {
			    color: $dark-grey; } }
		img {
			width: 60%;
			transform: scale(0);
			transform-origin: right bottom;
			position: absolute;
			bottom: 0;
			right: 0;
			left: 330px;
			transition: all 0.5s; } }


	nav {
		grid-area: menu;
		ul {
			list-style: none;
			display: flex;
			&:first-child {
				margin-bottom: 40px;
				justify-content: center;
				flex-direction: column;
				li {
					margin-bottom: 10px;
					a {
						display: inline-block;
						width: 100%;
						text-decoration: none;
						font-family: $Font-Black;
						font-size: 50px;
						color: $white;
						transition: all 0.5s;
						position: relative;
						&:hover {
							&:after {
								width: 100%; } }
						&:after {
							content: attr(data-text);
							position: absolute;
							width: 0;
							overflow: hidden;
							color: $yellow;
							left: 0;
							top: 50%;
							transform: translateY(-50%);
							white-space: nowrap;
							transition: all 1s; } }
					&:last-child {
						margin-bottom: 0 !important; }
					&:nth-last-child(1), &:nth-last-child(2) {
						a {
							font-size: 30px; } } } }
			&:last-child {
				li {
					margin: 0 17px;
					&:first-child {
						margin-left: 0; }
					&:last-child {
						margin-right: 0; }
					svg {
						font-size: 25px;
						color: rgba($white, 0.2);
						transition: all 0.5s;
						&:hover {
							color: $yellow; } } } } } } }

///////////////////
@media only screen and (max-width: 768px) {
 .menu {}
	&>div {
		padding-bottom: 70px;
		padding-left: 70px;
		border-right: 1px solid rgba($black, 0.2);
		&:hover {
			&>div a {}
			&>div {
				transform: translateY(-45vh); }
			img {
				height: auto;
				transform: scale(1); } }
		&>div {
			h1 {
				font-size: 55px !important; }
			p {
				font-size: 18px !important;
				font-family: $Font-Medium; } }
		img {
			width: 60%;
			transform: scale(0);
			left: 330px; } }
	nav {
		ul {
			&:first-child {
				margin-bottom: 40px;
				li {
					margin-bottom: 10px;
					a {
						font-size: 42px !important;
						&:after {
							white-space: break-spaces; } }
					&:last-child {
						margin-bottom: 0 !important; }
					&:nth-last-child(1), &:nth-last-child(2) {
						a {
							font-size: 28px; } } } }
			&:last-child {
				li {
					margin: 0 17px;
					svg {
						font-size: 25px;
						color: rgba($black, 0.2);
						transition: all 0.5s; } } } } } }
////////////////////
@media only screen and (max-width: 767px) {
	.menu {
		grid-template-columns: 1fr;
		grid-template-rows: 1fr auto;
		grid-template-areas: "menu" "get-quote";
		&>div {
			display: flex;
			background-color: $yellow;
			color: $white;
			padding: 45px 0 45px 15px;
			align-items: flex-start;
			align-self: unset;
			height: unset;
			&:hover {
				&>div {
					transform: unset; } }
			&>* {
				flex: 1; }
			&>div {
				height: unset;
				h1 {
					font-size: 40px;
					line-height: 1;
					font-family: $Font-Black; }
				p {
					font-size: 16px;
					font-family: $Font-Medium; } }
			img {
				transform: scale(1);
				width: 100%;
				position: relative;
				max-width: 183px;
				margin-top: -100px; } }
		nav {
			padding-left: 15px;
			ul {
				&:first-child {
					li {
						a {
							font-size: 30px !important; }
						&:nth-last-child(1), &:nth-last-child(2) {
							a {
								font-size: 20px; } } } } } } } }

