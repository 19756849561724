@import "../../../variables.sass";
.contactSectionContainer {
	padding: 100px 0;
	background-color: #202020;
	p {
		&:first-of-type {
			color: $white;
			font-family: $Font-Bold;
			font-size: 16px;
			margin-bottom: 20px;
			span {
					color: rgba($white, 0.4); } }
		&:last-of-type {
			font-family: $Font-Medium;
			color: rgba($white, 0.6);
			font-size: 26px; } }
	button {
		background-color: transparent;
		border: none;
		text-decoration: underline;
		color: $white;
		font-family: $Font-Black;
		font-size: 60px;
		outline: none;
		cursor: pointer; } }

@media only screen and (max-width: 991px) {
	.contactSectionContainer {
		padding: 80px 0;
		p {
			&:nth-child(2) {
				font-size: 30px;
				line-height: 40px; } }
		button {
			font-size: 40px;
			line-height: 120px; } } }
@media only screen and (max-width: 575.98px) {
    .contactSectionContainer {
	    padding: 50px 0 !important;
    	p {
	    	&:first-of-type {
		    	font-size: 16px !important;
		    	margin-bottom: 20px; }
	    	&:last-of-type {
		    	font-size: 26px; } }
    	button {
		    font-size: 40px !important; } } }


