@import '../../../variables.sass';
.brandRefreshContainer {
 background: $linear-yellow;
 background-color: $yellow;
 color: $dark-grey;
 padding: 100px 80px;
 a {
  font-size: 32px;
  font-family: $Font-Bold;
  color: $dark-grey;
  padding-bottom: 20px; }
 p {
  font-size: 18px;
  font-family: $Font-Medium;
  padding-bottom: 70px;
  white-space: break-spaces; }
 i {
  font-size: 18px;
  font-family: $Font-Regular; }
 .speackWithOurStrategist {
  background-color: rgba(225,225,225,0.3);
  color: $white;
  width: 406px;
  height: 140px;
  border-radius: 5px;
  position: relative;
  cursor: default;
  margin-top: 10px;
  h3 {
   position: absolute;
   top: 50%;
   opacity: .2;
   transform: translateY(-50%);
   text-align: center;
   font-size: 36px;
   font-family: $Font-Black;
   text-transform: uppercase;
   text-align: left;
   padding-left: 20px; }
  &:hover {
    h3 {
     opacity: 1; } } } }
@media only screen and (max-width: 575.98px) {
 .brandRefreshContainer {
  padding: 50px 0px !important;
  h3 {
   font-size: 28px !important; }
  p {
   font-size: 16px !important;
   padding-bottom: 30px !important; }
  i {
   font-size: 16px !important; }
  .speackWithOurStrategist {
   width: 303px !important;
   height: 120px !important;
   h3 {
    font-size: 28px !important; } } } }




