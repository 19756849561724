.clickedSectionSliderImageContainer {
 button {
  position: absolute;
  bottom: -45px;
  background-color: transparent;
  color: #000;
  border: none;
  outline: none !important;
  font-size: 30px;
  cursor: pointer;
  &[data-role="slick-next"] {
   right: 0; } } }
