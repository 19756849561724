@import '../../../variables.sass';
.dominosFirstSectionContainer {
 padding: 136px 0 100px;
 p {
  font-size: 18px;
  font-family: $Font-Medium;
  color: $dark-grey;
  opacity: .4; }
 h2 {
  font-size: 100px;
  font-family: $Font-Black;
  color: $dark-grey;
  white-space: break-spaces; } }
