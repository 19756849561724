@import '../../../variables.sass';
.personList {
 position: relative;
 padding-bottom: 100px;
 .imgBox {
  position: relative;
  height: 540px;
  img {
   height: 100%;
   -o-object-fit: cover;
   object-fit: cover;
   max-width: none; } }
 .descr {
  text-align: left;
  padding-top: 40px;
  h3 {
   font-size: 26px;
   font-family: $Font-Bold;
   color: $yellow;
   text-shadow: 1px 1px #70707061; }
  p {
   font-size: 20px;
   font-family: $Font-Regular;
   color: $dark-grey; } } }
@media only screen and (max-width: 575.98px) {
 .personList {
  padding-bottom: 50px;
  .descr {
   padding-top: 20px;
   h3 {
    font-size: 18px; }
   p {
    font-size: 16px; } } } }





